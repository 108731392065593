import React from 'react';
import { COLORS } from '@theme';

const VpnMentor = ({ color = COLORS.darkBlue }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 148 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.403 21.984 34.445 10.43h3.49l2.468 5.416 2.587-5.416H46l-5.597 11.554ZM73.922 21.803V4.893l8.786 6.62 8.786-6.62v16.91h-3.49v-9.748l-5.296 3.67-5.236-3.67v9.748h-3.55Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m1.275 4.763 12.86 9.656 12.883-9.675c-1.28-.05-4.354-.224-6.286-.744-2.763-.744-6.495-3.057-6.495-3.057S10.415 3.255 7.74 4c-2.32.646-6.101.754-6.466.763Zm25.986-.01c.065 1.088.552 11.235-3.316 18.8a13.946 13.946 0 0 1-1.831 2.72v-11.04l-7.98 5.512-7.889-5.513v10.67c3.477 4.137 7.991 5.607 7.991 5.607s6.858-2.006 9.935-8.024c4.092-8.003 3.438-18.721 3.438-18.721l-.348-.01ZM133.56 21.864a5.837 5.837 0 1 0 0-11.674 5.837 5.837 0 0 0 0 11.674Zm.06-3.25a2.648 2.648 0 1 0 .001-5.295 2.648 2.648 0 0 0-.001 5.295ZM58.393 16.027c0 3.223-2.479 5.837-5.536 5.837-.959 0-1.86-.257-2.646-.709v5.884h-3.25V10.43h3.25v.468a5.289 5.289 0 0 1 2.646-.708c3.057 0 5.536 2.613 5.536 5.837Zm-3.25-.06c0 1.462-1.159 2.647-2.588 2.647s-2.587-1.185-2.587-2.648c0-1.462 1.158-2.647 2.587-2.647 1.43 0 2.588 1.185 2.588 2.647ZM98.894 18.674l6.139-4.513s-1.144-4.06-5.717-3.911c-3.276.106-5.757 2.988-5.537 6.258.206 3.052 2.486 5.136 5.537 5.356 3.61.26 5.235-2.287 5.235-2.287l-2.287-1.865s-.873.769-1.564.962c-.679.19-1.806 0-1.806 0Zm-1.797-2 3.972-2.828s-1.444-1.055-2.889-.18c-1.564.946-1.083 3.008-1.083 3.008ZM120.859 9.046l3.25-2.347v3.731h2.649v3.01h-2.649v3.008h.001l-.001.03v.066c.005.28.07 1.106.663 1.65.755.692 1.865.48 1.865.48v3.25s-1.805.241-3.851-1.203c-1.685-1.19-1.901-3.319-1.924-4.032h-.003v-3.25h-1.683V10.43h1.683V9.046ZM63.214 11.211a5.115 5.115 0 0 1 8.183 4.093v6.44h-3.129v-5.898a2.527 2.527 0 0 0-5.054-.075v5.973h5.054-8.303V10.43h3.25v.781ZM109.91 11.211a5.115 5.115 0 0 1 8.183 4.093v6.44h-3.128v-5.898a2.528 2.528 0 0 0-5.055-.02v5.918h5.055-8.305V10.43h3.25v.781ZM144.332 21.743h-3.25V10.43h3.25v.78a5.09 5.09 0 0 1 3.25-1.018v3.23a2.531 2.531 0 0 0-3.249 2.424v5.897h-.001Zm.001 0h3.249-3.249Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.276.424-.253.152-.002.001-.008.005-.03.018a22.78 22.78 0 0 1-.58.335c-.39.221-.938.524-1.569.85-1.27.656-2.842 1.389-4.127 1.746-1.172.327-2.743.52-4.042.63a46.256 46.256 0 0 1-2.232.133l-.033.001h-.01l-.434.012-.05.431.497.057a62.468 62.468 0 0 0-.497-.056v.002l-.001.007-.003.025a11.54 11.54 0 0 0-.042.459c-.025.315-.056.772-.079 1.345a42.813 42.813 0 0 0 .074 4.632c.262 3.736 1.099 8.542 3.42 12.725 1.766 3.182 4.228 5.254 6.24 6.529a18.843 18.843 0 0 0 2.554 1.36 13.93 13.93 0 0 0 .983.384l.058.019.017.005.004.001h.002l.148-.477-.147.478.145.045.145-.044-.143-.479.143.48v-.001h.002l.005-.002.018-.005.063-.02a16.289 16.289 0 0 0 1.079-.403 20.872 20.872 0 0 0 2.758-1.396c2.144-1.291 4.71-3.368 6.306-6.49 2.089-4.085 2.924-8.883 3.242-12.634.16-1.88.19-3.507.181-4.666a39.95 39.95 0 0 0-.057-1.823l-.002-.026V4.76l-.5.035.5-.035-.033-.452-.453-.012h-.01l-.034-.002a25.44 25.44 0 0 1-.619-.026c-.416-.02-.997-.055-1.654-.11-1.323-.112-2.926-.305-4.126-.628-1.33-.358-2.963-1.091-4.283-1.748A49.287 49.287 0 0 1 14.573.596l-.032-.019-.008-.004-.002-.001-.255-.148ZM1.856 5.312l.004-.033a43.312 43.312 0 0 0 1.89-.122c1.31-.11 2.958-.309 4.226-.662 1.389-.387 3.036-1.16 4.317-1.821a47.428 47.428 0 0 0 1.992-1.092l.411.227c.415.226.997.535 1.666.868 1.33.662 3.037 1.433 4.468 1.819 1.291.348 2.969.546 4.302.658a50.189 50.189 0 0 0 1.913.124V5.3c.014.309.028.76.033 1.327.01 1.134-.02 2.73-.177 4.573-.314 3.696-1.133 8.346-3.136 12.264-1.481 2.896-3.88 4.852-5.932 6.088a19.872 19.872 0 0 1-2.625 1.327 15.405 15.405 0 0 1-.924.35 12.91 12.91 0 0 1-.817-.323c-.61-.265-1.46-.682-2.417-1.288-1.914-1.212-4.237-3.172-5.9-6.17-2.222-4.001-3.04-8.643-3.297-12.309a41.813 41.813 0 0 1-.072-4.522c.022-.559.052-1.003.076-1.305Z"
      fill={color}
    />
  </svg>
);

export default VpnMentor;
